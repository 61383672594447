import React from "react";
import ProjectContainer from "../components/projectContainer";

function Home() {
  return (
    <div>
      <ProjectContainer />
    </div>
  );
}

export default Home;
