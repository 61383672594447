import React from "react";
import FunContainer from "../components/funContainer";

function Fun() {
  return (
    <div>
      <FunContainer />
    </div>
  );
}

export default Fun;
